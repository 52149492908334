.slider {
  position:absolute;
  width: 100%;
  height:5px;
  overflow-x: hidden;
}

.line {
  position:absolute;
  opacity: 0.4;
  width:150%;
  background: rgb(68, 86, 241);
  height:5px;
}

.topLine {
  position:absolute;
  height:5px; 
  background: #1856ff;
  animation: increase 2s infinite;
}
.bottomLine{
  position:absolute;
  height:5px; 
  background: rgb(52, 118, 240);
  animation: decrease 2s 0.5s infinite;
}

@keyframes increase {
 from { left: -5%; width: 5%; }
 to { left: 130%; width: 100%;}
}
@keyframes decrease {
 from { left: -80%; width: 80%; }
 to { left: 110%; width: 10%;}
}