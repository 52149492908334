.panel-override .ant-collapse-content-box {
  padding: 0;
}

.panel-override.ant-collapse {
  box-shadow: none;
}

input[readonly],textarea[readonly] {
  cursor: default;
}